import './EasySoftwareCaseStudies.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareCaseStudyItem, {
  EasySoftwareCaseStudyItemType,
} from './EasySoftwareCaseStudyItem'

export interface EasySoftwareCaseStudiesType {
  contentItemId?: string
  displayText: string
  caseStudiesList: {
    contentItems: [EasySoftwareCaseStudyItemType]
  }
}

const rootClass = `case-studies`

const EasySoftwareCaseStudies: FunctionComponent<EasySoftwareCaseStudiesType> = (
  props
) => (
  <div className={rootClass}>
    <h2 className={`${rootClass}-title`}>{props.displayText}</h2>
    <div className={`${rootClass}-items`}>
      {props.caseStudiesList &&
        props.caseStudiesList.contentItems &&
        props.caseStudiesList.contentItems.map((item) => (
          <EasySoftwareCaseStudyItem key={item.contentItemId} {...item} />
        ))}
    </div>
  </div>
)

export default EasySoftwareCaseStudies
